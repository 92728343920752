import React from 'react';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { useNavigate } from 'react-router-dom';
import MacroMateMain from './MacroMateMain';

const MacroMateClinical = () => {
    const navigate = useNavigate();

    return (
        <div className="UtilitiesContainer">
            <div className='NavigationContainer'>
                <BackIcon onClick={() => { navigate(-1) }} className="back-button" />
            </div>
            <MacroMateMain />
        </div >
    );
};

export default MacroMateClinical;