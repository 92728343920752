import React, { useEffect, useState } from 'react';
import styles from '../../assets/css/Profile.module.css';

import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { getNotificationSetting, getTokenFromLocalStorage, handleNotificationSetting, handleSubmit } from '../../helpers';
import { toast } from 'react-toastify';


const NotificationSettings = () => {
    const [isAllowSms, setIsAllowSms] = useState(false);
    const [isAllowEmail, setIsAllowEmail] = useState(false);
    const [fromWorkHours, setFromWorkHours] = useState("")
    const [toWorkHours, setToWorkHours] = useState("")

    const navigate = useNavigate();



    useEffect(() => {
        getNotificationSetting(setIsAllowEmail, setIsAllowSms, setFromWorkHours, setToWorkHours);
    }, [])


    return (
        <>
            <div className="NavigationContainer">
                <div className="back-button-container">
                    <BackIcon onClick={() => navigate(-1)} className="back-button" />
                </div>
            </div>
            <div className={styles.commonOuterProfileContainer}>
                <div className={styles.personalInformationContainer}>
                    <div className={styles.personalInformationheading}>
                        <h3 >Notification Settings</h3>
                        <p>By opting in, you agree to receive email and/or SMS alerts with important company updates, announcements, and work-related information.</p>
                    </div>
                    <div className={styles.notificationInfoRow1}>
                        <div className={styles.switch}>
                            <h4>Email :</h4>
                            <div
                                className={`${styles.toggleSwitch} ${isAllowEmail ? styles.active : styles.inactive}`}
                                onClick={() => handleNotificationSetting("email", setIsAllowEmail, isAllowEmail)}

                            >
                                <div className={styles.toggleButton}></div>
                                <span className={styles.toggleLabel}>
                                    {isAllowEmail ? 'Opt-In' : 'Opt-Out'}
                                </span>
                            </div>
                        </div>
                        <div className={styles.switch}>
                            <h4>SMS :</h4>
                            <div
                                className={`${styles.toggleSwitch} ${isAllowSms ? styles.active : styles.inactive}`}
                                onClick={() => handleNotificationSetting("sms", setIsAllowSms, isAllowSms)}
                            >
                                <div className={styles.toggleButton}></div>
                                <span className={styles.toggleLabel}>
                                    {isAllowSms ? 'Opt-In' : 'Opt-Out'}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.notificationInfoRow}>
                        <div className={styles.timeInputContainer}>
                            <span>Between the hours of:</span>
                            <div className={styles.timeInputWrapper}>
                                <input
                                    type="time"
                                    placeholder="Start Time"
                                    className={styles.timeInput}
                                    value={fromWorkHours}
                                    onChange={(e) => setFromWorkHours(e.target.value)} />
                                <span>and</span>
                                <input
                                    type="time"
                                    placeholder="End Time"
                                    className={styles.timeInput}
                                    value={toWorkHours}
                                    onChange={(e) => setToWorkHours(e.target.value)} />


                            </div>
                            <button onClick={() =>
                                handleSubmit(fromWorkHours, toWorkHours)}
                                className={styles.submitButton}
                            >Save</button>

                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default NotificationSettings;


