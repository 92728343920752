import React, { useEffect, useRef, useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from '../../assets/css/MacroMateClinical.module.css';
import Loader from './Loader';
import {
  fetchShortCuts,
  editExpansion,
  autoResize,
  searchShortCut,
  sortExpansions,
  handleExport,
  handleImportClick,
  handleFileChange,
  importExpansions,
  deleteExpansion,
  addExpansion,
} from '../../helpers/macromate-clinical';

export default function MacroMateMain() {
  const [shortcut, setShortcut] = useState('');
  const AllShortCuts = useRef([]);
  const [RenderExpansionData, setRenderExpansionData] = useState([]);
  const [expandedText, setExpandedText] = useState('');
  const [listExpandedText, setListExpandedText] = useState([]);
  const [editingId, setEditingId] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [loading, setLoading] = useState(false);
  const [expansionsOpen, setExpansionsOpen] = useState(false);
  const [AddExpansionsOpen, setAddExpansionsOpen] = useState(false);

  useEffect(() => {
    fetchShortCuts(AllShortCuts, setRenderExpansionData);
  }, []);

  const handleAccordionClick = (event) => {
    if (event.target.tagName === 'BUTTON') {
      return;
    }
    setExpansionsOpen(!expansionsOpen);
  };

  const handleAccordionClickForAddExpansions = (event) => {
    if (event.target.tagName === 'BUTTON' || event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
      return;
    }
    setAddExpansionsOpen(!AddExpansionsOpen);
  };

  const handleImport = () => {
    handleImportClick((event) => handleFileChange(event, (jsonData) =>
      importExpansions(jsonData, setLoading, AllShortCuts, setRenderExpansionData)
    ));
  };

  const handleAddExpansion = async (e) => {
    e.preventDefault();
    await addExpansion(shortcut, expandedText, AllShortCuts, setRenderExpansionData);
  };

  const handleEditExpansion = (shortcut, expandedText) => {
    editExpansion(shortcut, expandedText, true, setEditingId, AllShortCuts, setRenderExpansionData);
  };

  const handleDeleteExpansion = async (id) => {
    await deleteExpansion(id, AllShortCuts, setRenderExpansionData);
  };


  const handleSort = () => {
    sortExpansions(AllShortCuts.current, sortOrder, setRenderExpansionData, setSortOrder);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} className="UtilitiesMainContainer whiteContainerWide">
      <div style={{ height: '100%' }}>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <h1 className={styles.colorBlue}>MacroMate Clinical</h1>
          </div>
          <textarea style={{ height: '100%' }} onChange={(e) => { searchShortCut(e.target.value, AllShortCuts); autoResize(e); }} onInput={autoResize} disabled={false} className={styles.textClass} id="inputText" placeholder="Type your text here..."></textarea>
        </div >
      </div>

      <div className={styles.subContainer}>
        <Accordion
          expanded={AddExpansionsOpen}
          onClick={handleAccordionClickForAddExpansions}
          sx={{
            border: '1px solid #008CBA',
            '& .MuiAccordionSummary-content': {
              color: '#008CBA'
            }
          }}>
          <div className={styles.accordionMainContainer}>
            <div className={styles.accordionSubContainer}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="add-expansion-content"
                id="add-expansion-header"
              >
                <Typography>Add New Expansion</Typography>
              </AccordionSummary>
            </div>
          </div>
          <AccordionDetails>
            <label htmlFor="shortcut">Shortcut:</label>
            <input className={styles.textClass} onChange={(e) => setShortcut(e.target.value)} value={shortcut} type="text" id="shortcut" placeholder=".shortcut" />
            <br />
            <label htmlFor="expansion">Expansion:</label>
            <textarea className={styles.textClass} onChange={(e) => setExpandedText(e.target.value)} value={expandedText} id="expansion" placeholder="Expanded text"></textarea>
            <br />
            <div className={styles.buttonContainer}>
              <button className={styles.buttonClass} onClick={handleAddExpansion}>Add Expansion</button>
              <button onClick={handleImport} className={styles.buttonClass}>{loading ? <Loader height={'17px'} width={'17px'} /> : 'Import Expansions'}</button>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          onClick={handleAccordionClick}
          expanded={expansionsOpen}
          sx={{
            border: '1px solid #008CBA',
            '& .MuiAccordionSummary-content': {
              color: '#008CBA'
            }
          }}
        >
          <div className={styles.accordionMainContainer}>
            <div className={styles.accordionSubContainer}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="expansions-content"
                id="expansions-header"
                sx={{
                  margin: '0',
                  minHeight: '3rem !important'
                }}
              >
                <Typography>Current Expansions</Typography>
              </AccordionSummary>
            </div>
          </div>
          <AccordionDetails>
            <div className={styles.buttonContainer}>
              <button className={styles.buttonClass} onClick={handleSort}>Sort Alphabetically</button>
              <button className={styles.buttonClass} onClick={() => handleExport()}>Export Expansions</button>
            </div>
            <div>
              {RenderExpansionData.map((item, index) => {
                return <li key={index}>
                  <div style={{ fontSize: '0.8rem' }}>
                    <strong>Shortcut:</strong>{item.shortcut}<br />
                    <strong>Expansion:</strong>
                    <span className={styles.expansionText}>{editingId !== item.id && item.expansion}</span>
                    {editingId === item.id && <textarea onChange={(e) => setListExpandedText(e.target.value)} className={styles.editInput}>{item.expansion}</textarea>}
                  </div>
                  <div className={styles.editButtons}>
                    <button onClick={() => setEditingId(item.id)} className={styles.editButton}>Edit</button>
                    <button onClick={() => handleDeleteExpansion(item.id)}>Remove</button>
                    {editingId === item.id && <button onClick={() => handleEditExpansion(item.shortcut, listExpandedText)} className={styles.saveButton}>Save</button>}
                    {editingId === item.id && <button onClick={() => setEditingId('')} className={styles.cancelButton}>Cancel</button>}
                  </div>
                </li>
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
}
