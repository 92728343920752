import React from 'react';
import styles from "../../assets/css/DiagnosisCode.module.css";

function DiagnosisCode(props) {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.column}>
          <h2 className={styles.title}>Diagnosis Codes</h2>
          <input type="text" className={styles.searchBar} placeholder="Search" />
          <div className={styles.listContainer}>
            <p>99201 : Shigellosis, unspecified Shigellosis, unspecified</p>
            <p>99202 :Shigellosis, unspecified </p>
            <p>99203 : Shigellosis, unspecified</p>
            <p>99204 : Shigellosis, unspecified</p>
            <p>99205 : Shigellosis, unspecified</p>
          </div>

        </div>

        <div className={styles.column}>
          <h2 className={styles.title}>Selected Codes</h2>
          <div className={styles.selectedContainer}>
            <p>No codes selected.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiagnosisCode;
