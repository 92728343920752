import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styles from '../../assets/css/NoteForm.module.css';
import Loader from '../reusable/Loader';
import VisitCode from './VisitCode';
import DiagnosisCode from './DiagnosisCode';

function NoteForm() {
  const [admitDate, setAdmitDate] = useState("");
  const [visitCodes, setVisitCodes] = useState("");
  const [diagnosisCodes, setDiagnosisCodes] = useState("");
  const [addToCharges, setAddToCharges] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const handleChange = (field, value) => {
    if (field === "admitDate") setAdmitDate(value);
    if (field === "visitCodes") setVisitCodes(value);
    if (field === "diagnosisCodes") setDiagnosisCodes(value);
    if (field === "addToCharges") setAddToCharges(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!admitDate) {
      toast.error("Please select an admit date.");
      return;
    }
    if (!visitCodes.trim()) {
      toast.error("Please enter visit codes.");
      return;
    }
    if (!diagnosisCodes.trim()) {
      toast.error("Please enter diagnosis codes.");
      return;
    }

    try {
      const response = await fetch("", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ admitDate, visitCodes, diagnosisCodes, addToCharges }),
      });

      const result = await response.json();
      if (response.ok) {
        toast.success("note submitted successfully!");
      } else {
        toast.error(result.message || "Failed to submit form.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.content} style={{ backgroundColor: "white", borderRadius: "40px" }}>
      <form onSubmit={handleSubmit} className={styles.subContent}>

        <div className={styles.columnContainer}>
          <div className={styles.inputWrapper}>
            <label htmlFor="admitDate">Admit Date <span className={styles.required}>*</span></label>
            <input
              id="admitDate"
              type="date"
              className={styles.dateField}
              value={admitDate}
              onChange={(e) => handleChange("admitDate", e.target.value)}
              required
            />
          </div>

          <VisitCode visitCodes={visitCodes} handleChange={handleChange} />
          <DiagnosisCode visitCodes={visitCodes} handleChange={handleChange} />

          <div className={styles.inputWrapper}>
            <label htmlFor="SharedVisit">Shared Visit<span className={styles.required}>*</span></label>
            <select
              id="SharedVisit"
              className={styles.selectInputField}
              value={''}
              onChange={(e) => { }}>
              <option className={styles.option} value="">Select option</option>
              <option className={styles.option} value="shared" selected={""}>shared</option>
            </select>
          </div>

          <div className={styles.checkboxContainer}>
            <span>Add to Charges</span>
            <input id="addToCharges" type="checkbox" checked={addToCharges} onChange={(e) => handleChange("addToCharges", e.target.checked)} />
          </div>

          {loading ? (
            <div style={{ margin: '3px' }}><Loader /></div>
          ) : (
            <button type="submit" className={styles.rectangleBtn} >Save</button>
          )}
        </div>
      </form>
    </div>
  )
}

export default NoteForm