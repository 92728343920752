import React, { useEffect, useState } from 'react';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg'
import styles from '../../assets/css/ConsultsTrackingTable.module.css'
import { useNavigate } from 'react-router-dom'
import { convertToCustomDate, getTokenFromLocalStorage, isValidDate, columnWidths, ViewCalendarDate } from '../../helpers';
import { toast } from 'react-toastify';

const TableRowForConsultsTracking = ({ consults, isEditMode, setConsults, visibleColumns }) => {
    const navigate = useNavigate();
    const isMobile = window.innerWidth <= 630;

    const EditDetails = (consults) => {
        navigate('/consult-details', {
            state: {
                consults: consults,
                mode: 'view&edit',
            }
        });
    };

    const [editableFields, setEditableFields] = useState({
        initialconsultcomplete: consults.initialconsultcomplete || '',
        notes: consults.notes || '',
        rehabrecs: consults.rehabrecs || '',
        rehabdiagnosis: consults.rehabdiagnosis || '',
        insurancecarrier: consults.insurancecarrier || '',
        visitdate: consults.visitdate || ''
    });
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        setEditableFields({
            initialconsultcomplete: consults.initialconsultcomplete || '',
            notes: consults.notes || '',
            rehabrecs: consults.rehabrecs || '',
            rehabdiagnosis: consults.rehabdiagnosis || '',
            insurancecarrier: consults.insurancecarrier || '',
            visitdate: consults.visitdate || ''
        });
    }, [consults]);

    useEffect(() => {
        if (!isEditMode) {
            setHasChanges(false);
        }
    }, [isEditMode]);

    useEffect(() => {
        if (hasChanges) {
            handleBlur('initialconsultcomplete', editableFields.initialconsultcomplete);
        }
    }, [editableFields.initialconsultcomplete]);

    useEffect(() => {
        if (hasChanges) {
            handleBlur('rehabrecs', editableFields.rehabrecs);
        }
    }, [editableFields.rehabrecs]);

    const handleFieldChange = (fieldName, newValue) => {
        if (fieldName === 'visitdate' && !newValue) {
            setEditableFields(prev => ({
                ...prev,
                [fieldName]: ''
            }));
            setHasChanges(true);
            return;
        }

        if (newValue !== consults[fieldName]) {
            setEditableFields(prev => ({
                ...prev,
                [fieldName]: newValue
            }));
            setHasChanges(true);
        }
    };

    const handleBlur = async (fieldName, value) => {
        if (!hasChanges || value === consults[fieldName]) {
            return;
        }
        const formData = new URLSearchParams();

        const nonRequiredFields = [
            'roomnumber',
            'visitdate',
            'insurancecarrier',
            'rehabdiagnosis',
            'rehabrecs',
            'notes',
            'initialconsultcomplete'
        ];

        formData.append('id', consults.id);
        if (nonRequiredFields.includes(fieldName) && (!value || value === '')) {
            formData.append(fieldName, null);
        } else {
            formData.append(fieldName, value);
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/add-or-update-consult`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer " + getTokenFromLocalStorage(),
                },
                body: formData.toString(),
            });

            if (response.ok) {
                setConsults(prevConsults =>
                    prevConsults.map(item =>
                        item.id === consults.id
                            ? { ...item, [fieldName]: value || null }
                            : item
                    )
                );
                toast.success('Updated Successfully');
            }
        } catch (error) {
            console.error('Error updating fields:', error);
            toast.error('Update failed');
        }
    };

    const handleStatusToggle = async () => {
        const newStatus = consults.status === 'open' ? 'resolved' : 'open';
        const formData = new URLSearchParams();

        formData.append('id', consults.id);
        formData.append('status', newStatus);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/add-or-update-consult`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer " + getTokenFromLocalStorage(),
                },
                body: formData.toString(),
            });

            if (response.ok) {
                setConsults((prevConsults) =>
                    prevConsults.map((list) =>
                        list.id === consults.id ? { ...list, status: newStatus } : list
                    )
                );
            } else {
                throw new Error('Failed to update consults status');
            }
        } catch (error) {
            console.error('Error updating status:', error);
            alert('Error updating status');
        }
    };

    function capitalizeNames(firstname, lastname) {
        const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        const capitalizedFirstName = firstname ? capitalizeFirstLetter(firstname) : '';
        const capitalizedLastName = lastname ? capitalizeFirstLetter(lastname) : '';
        return `${capitalizedLastName}, ${capitalizedFirstName}`;
    }

    return (
        <div className={styles.flexRow}>
            <div onClick={() => !isEditMode && EditDetails(consults)} className={styles.flexCell} style={{ width: columnWidths.checkbox }}>
                <Eye fill={'var(--purple)'} className={styles.listIcons} />
            </div>

            {visibleColumns.name && (
                <div className={styles.flexCell} style={{ width: columnWidths.name }}>
                    {capitalizeNames(consults.firstname, consults.lastname)}
                </div>
            )}

            {visibleColumns.facilityName && (
                <div className={styles.flexCell} style={{ width: columnWidths.facilityName }}>
                    {consults.hospital_abbreviation}
                </div>
            )}

            {visibleColumns.roomNumber && (
                <div className={styles.flexCell} style={{ width: columnWidths.roomNumber }}>
                    {consults.roomnumber}
                </div>
            )}

            {visibleColumns.dob && (
                <div className={styles.flexCell} style={{ width: columnWidths.dob }}>
                    {isValidDate(consults.dob) && convertToCustomDate(consults.dob)}
                </div>
            )}

            {visibleColumns.dateRequested && (
                <div className={styles.flexCell} style={{ width: columnWidths.dateRequested }}>
                    {isValidDate(consults.daterequested) && convertToCustomDate(consults.daterequested)}
                </div>
            )}

            {visibleColumns.timeRequested && (
                <div className={styles.flexCell} style={{ width: columnWidths.timeRequested }}>
                    {consults.timerequested}
                </div>
            )}

            {visibleColumns.visitDate && (
                <div className={styles.flexCell} style={{ width: columnWidths.visitDate }}>
                    {isEditMode ? (
                        <input
                            type="date"
                            value={editableFields.visitdate && isValidDate(editableFields.visitdate) ? ViewCalendarDate(editableFields.visitdate) : ''}
                            onChange={(date) => handleFieldChange('visitdate', date.target.value)}
                            onBlur={() => handleBlur('visitdate', editableFields.visitdate)}
                            mode="edit"
                            subMode="edit"
                            placeholder="Visit Date"
                            required={true}
                            className={styles.dateField}
                        />
                    ) : (
                        isValidDate(consults.visitdate) && convertToCustomDate(consults.visitdate)
                    )}
                </div>
            )}

            {visibleColumns.assignedProvider && (
                <div className={styles.flexCell} style={{ width: columnWidths.assignedProvider }}>
                    {consults.owning_provider_name}
                </div>
            )}

            {visibleColumns.insuranceCarrier && (
                <div className={styles.flexCell} style={{ width: columnWidths.insuranceCarrier }}>
                    {isEditMode ? (
                        <input
                            type="text"
                            value={editableFields.insurancecarrier}
                            onChange={(e) => handleFieldChange('insurancecarrier', e.target.value)}
                            onBlur={() => handleBlur('insurancecarrier', editableFields.insurancecarrier)}
                            className={styles.editableInput}
                        />
                    ) : (
                        consults.insurancecarrier
                    )}
                </div>
            )}

            {visibleColumns.rehabDiagnosis && (
                <div className={styles.flexCell} style={{ width: columnWidths.rehabDiagnosis }}>
                    {isEditMode ? (
                        <input
                            type="text"
                            value={editableFields.rehabdiagnosis}
                            onChange={(e) => handleFieldChange('rehabdiagnosis', e.target.value)}
                            onBlur={() => handleBlur('rehabdiagnosis', editableFields.rehabdiagnosis)}
                            className={styles.editableInput}
                        />
                    ) : (
                        consults.rehabdiagnosis
                    )}
                </div>
            )}

            {visibleColumns.rehabRecs && (
                <div className={styles.flexCell} style={{ width: columnWidths.rehabRecs }}>
                    {isEditMode ? (
                        <select
                            value={editableFields.rehabrecs}
                            onChange={(e) => {
                                setEditableFields((prev) => ({
                                    ...prev,
                                    rehabrecs: e.target.value
                                }));
                                setHasChanges(true);
                            }}
                            className={styles.selectInputField}
                        >
                            <option value="">Select Rehab Recs</option>
                            <option value="IRF">IRF</option>
                            <option value="SNF">SNF</option>
                            <option value="HH">HH</option>
                            <option value="Other">Other</option>
                        </select>
                    ) : (
                        consults.rehabrecs
                    )}
                </div>
            )}

            {visibleColumns.notes && (
                <div className={styles.flexCell} style={{ width: columnWidths.notes }}>
                    {isEditMode ? (
                        <input
                            type="text"
                            value={editableFields.notes}
                            onChange={(e) => handleFieldChange('notes', e.target.value)}
                            onBlur={() => handleBlur('notes', editableFields.notes)}
                            className={styles.editableInput}
                        />
                    ) : (
                        consults.notes
                    )}
                </div>
            )}

            {visibleColumns.initialConsultComplete && (
                <div className={styles.flexCell} style={{ width: columnWidths.initialConsultComplete }}>
                    {isEditMode ? (
                        <select
                            className={styles.selectInputField}
                            value={editableFields.initialconsultcomplete}
                            onChange={(e) => {
                                setEditableFields((prev) => ({
                                    ...prev,
                                    initialconsultcomplete: e.target.value
                                }));
                                setHasChanges(true);
                            }}
                        >
                            <option value="">Select Initial Consult Complete</option>
                            <option value="Yes">Yes</option>
                            <option value="Scheduled">Scheduled</option>
                        </select>
                    ) : (
                        consults.initialconsultcomplete
                    )}
                </div>
            )}

            {visibleColumns.status && (
                <div className={styles.flexCell} style={{ width: columnWidths.status }}>
                    {isEditMode ? (
                        <div
                            className={`${styles.toggleSwitch} ${consults.status === 'open' ? styles.active : styles.inactive}`}
                            onClick={handleStatusToggle}
                        >
                            <div className={styles.toggleButton}></div>
                            <span className={styles.toggleLabel}>
                                {consults.status === 'open' ? 'open' : 'resolved'}
                            </span>
                        </div>
                    ) : (
                        consults.status
                    )}
                </div>
            )}
        </div>
    )
};

export default TableRowForConsultsTracking
