import { useRef } from 'react';
import { getTokenFromLocalStorage } from '../../helpers';
import { DesktopHomeNavbar } from './DesktopHomeNavbar';
import { MobileHomeNavbar } from './MobileHomeNavbar';
import { useLocation } from 'react-router-dom';

export const HomeNavbar = () => {
  const location = useLocation();
  const token = useRef(getTokenFromLocalStorage());
  if (location.pathname === '/patient-list' || location.pathname === '/patient' || location.pathname === '/charges' || location.pathname === '/utilities' || location.pathname === '/consults-tracking-table' || location.pathname === '/consult-details' || location.pathname === '/macromate-clinical' || location.pathname === '/suggestions-and-feedback' || location.pathname.startsWith('/profile/') || location.pathname === '/training-materials' || location.pathname === 'add-note') {
    return null;
  }
  if (token.current !== null) {
    return null;
  }

  return (
    <>
      <DesktopHomeNavbar className="hide-on-mobile" />
      <MobileHomeNavbar />
    </>
  );
};