import React from 'react';
import MacroMateMain from '../reusable/MacroMateMain.js';
import NoteForm from './NoteForm.js';
import styles from '../../assets/css/Note.module.css';

const Note = () => {
    return (
        <div className={styles.noteContainer}>
            <div className={styles.macroMate}>
                <div className={styles.macroMateMain}>
                    <MacroMateMain />
                </div>
            </div>
            <div className={styles.noteForm}>
                <NoteForm />
            </div>
        </div>
    );
};

export default Note;