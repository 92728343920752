import React, { useState, useEffect, useCallback } from 'react';
import nobodyAvatar from '../../assets/images/nobody-avatar.jpg';
import { getTokenFromLocalStorage } from '../../helpers';
import styles from '../../assets/css/Directory.module.css'
import '../../App.css';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { useNavigate } from 'react-router-dom';

const Directory = () => {
    const [allUsers, setAllUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const navigate = useNavigate();

    const getAllUsers = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/users/all-users-with-pics`,
                {
                    headers: {
                        'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                    }
                }
            );
            const data = await response.json();
            setAllUsers(data.users);
            setFilteredUsers(data.users);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching users:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (allUsers.length === 0) {
            getAllUsers();
        }
    }, []);

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchQuery(searchTerm);

        if (!searchTerm) {
            setFilteredUsers(allUsers);
            return;
        }

        const filtered = allUsers.filter(user => {
            const fullName = `${user.firstname} ${user.lastname}`.toLowerCase();
            const email = user.email.toLowerCase();
            const title = user.title.toLowerCase();

            return fullName.includes(searchTerm) ||
                email.includes(searchTerm) ||
                title.includes(searchTerm);
        });

        setFilteredUsers(filtered);
    };
    const ShimmerCard = () => (
        <div className={styles['directory-card']} style={{ borderLeft: '4px solid #f0f0f0' }}>
            <div className={styles['directory-info']}>
                <div className={`${styles.shimmer}`} style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                <div className={styles['directory-name']} style={{ marginLeft: '20px' }}>
                    <div className={`${styles.shimmer}`} style={{ width: '180px', height: '20px', marginBottom: '8px' }} />
                    <div className={`${styles.shimmer}`} style={{ width: '140px', height: '16px', marginBottom: '4px' }} />
                    <div className={`${styles.shimmer}`} style={{ width: '200px', height: '16px' }} />
                </div>
            </div>
            <div className={styles['directory-position']}>
                <div className={`${styles.shimmer}`} style={{ width: '120px', height: '16px', marginBottom: '8px' }} />
                <div className={`${styles.shimmer}`} style={{ width: '100px', height: '16px' }} />
            </div>
            <div className={styles['directory-contact']}>
                <div className={`${styles.shimmer}`} style={{ width: '150px', height: '16px', marginBottom: '8px' }} />
                <div className={`${styles.shimmer}`} style={{ width: '180px', height: '16px' }} />
            </div>
        </div>
    );

    return (
        <div className={styles.directoryUtilitiesContainer}>
            <div className='NavigationContainer'>
                <BackIcon onClick={() => { navigate(-1) }} className="back-button" />
            </div>
            <div className={`${styles.directoryMainContainer} ${styles.directoryWhiteContainer}`}>
                <div className={styles['directory-container']}>
                    <div className={styles['directory-searchHeader']}>
                        <input
                            type="text"
                            placeholder="Search Directory"
                            className={styles['directory-searchInput']}
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                    </div>

                    <div className={styles['directory-list']}>
                        {loading ? (
                            Array(5).fill(null).map((_, index) => <ShimmerCard key={index} />)
                        ) : (
                            (searchQuery ? filteredUsers : allUsers).map((item) => (
                                <div className={styles['directory-card']} key={item.id}>
                                    <div className={styles['directory-info']}>
                                        <img
                                            src={item.profile_pic_url || nobodyAvatar}
                                            alt={`${item.firstname} ${item.lastname}`}
                                            className={styles['directory-avatar']}
                                        />
                                        <div className={styles['directory-name']}>
                                            <h4>{`${item.firstname} ${item.lastname}`}</h4>
                                            <span className={styles['mobile-view-title']}>{item.title}</span>
                                            <span className={styles['directory-username']}>
                                                {item.email}
                                            </span>
                                            <div className={styles['desktop-view-company-name']}>
                                                {item.company_name}
                                            </div>
                                            <span className={styles['mobile-view-phone']}>
                                                {item.phone}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={styles['directory-position']}>
                                        <p className={styles['position-title']}>{item.title}</p>
                                        <p>{item.division}</p>
                                    </div>
                                    <div className={styles['directory-contact']}>
                                        <p>{item.phone}</p>
                                        <p>{item.email}</p>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Directory;
