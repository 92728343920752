import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteAllPatients, fetchHospitals, getPatients } from '../../helpers/index';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/charges-plus.svg'
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import styles from '../../assets/css/PatientList.module.css'
import '../../App.css';
import TableRow from '../reusable/TableRow';
import DashboardStats from '../reusable/DashboardStats';

const PatientList = () => {
    const navigate = useNavigate();
    const [patients, setPatients] = useState([]);
    const [patientStatus, setPatientStatus] = useState('');
    const [originalPatients, setOriginalPatients] = useState([]);
    const [sortOrder, setSortOrder] = useState({ column: null, order: null });
    const [searchQuery, setSearchQuery] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [filters, setFilters] = useState(() => {
        const savedFilters = localStorage.getItem('patientTableFilters');
        return savedFilters ? JSON.parse(savedFilters) : {
            facilityName: '',
            assignedProvider: '',
            status: 'active'
        };
    });
    const [hospitals, setHospitals] = useState([]);

    useEffect(() => {
        fetchHospitals().then(data => {
            setHospitals(data);
        });
    }, []);

    useEffect(() => {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            navigate('/signin');
        }

        getPatients().then((p) => {
            setOriginalPatients(p);
            const filteredResults = p.filter(patient => {
                const matchesFacility = !filters.facilityName ||
                    patient.hospital_id === parseInt(filters.facilityName);
                const matchesProvider = !filters.assignedProvider ||
                    patient.owning_provider_name?.toLowerCase().includes(filters.assignedProvider.toLowerCase());
                const matchesStatus = !filters.status ||
                    patient.status?.toLowerCase() === filters.status.toLowerCase();
                const matchesSearch = !searchQuery ||
                    `${patient.firstname} ${patient.middlename} ${patient.lastname}`.toLowerCase().includes(searchQuery.toLowerCase());

                return matchesFacility && matchesProvider && matchesStatus && matchesSearch;
            });
            setPatients(filteredResults);
        });
    }, [navigate]);

    const handleSort = (column) => {
        let order = 'asc';
        if (sortOrder.column === column && sortOrder.order === 'asc') {
            order = 'desc';
        }

        setSortOrder({ column, order });

        const sortedPatients = [...patients].sort((a, b) => {
            if (column === 'facesheetalias') {
                if (a.facesheetalias === b.facesheetalias) {
                    const nameA = `${a.firstname} ${a.middlename} ${a.lastname}`.toLowerCase();
                    const nameB = `${b.firstname} ${b.middlename} ${b.lastname}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                }
                return order === 'asc'
                    ? (a.facesheetalias ? -1 : 1)
                    : (a.facesheetalias ? 1 : -1);
            }

            if (column === 'hospitalfacilityname') {
                const facilityA = (a.hospital_abbreviation || '').toLowerCase();
                const facilityB = (b.hospital_abbreviation || '').toLowerCase();

                if (facilityA === facilityB) {
                    const nameA = `${a.firstname} ${a.lastname}`.toLowerCase();
                    const nameB = `${b.firstname} ${b.lastname}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                }

                return order === 'asc'
                    ? facilityA.localeCompare(facilityB)
                    : facilityB.localeCompare(facilityA);
            }

            if (column === 'provider') {
                const providerA = (a.owning_provider_name || '').toLowerCase();
                const providerB = (b.owning_provider_name || '').toLowerCase();

                if (providerA < providerB) return order === 'asc' ? -1 : 1;
                if (providerA > providerB) return order === 'asc' ? 1 : -1;
                return 0;
            }

            if (a[column] === null) return -1;
            if (b[column] === null) return -1;
            const locationA = a[column].toLowerCase();
            const locationB = b[column].toLowerCase();

            if (locationA < locationB) return order === 'asc' ? -1 : 1;
            if (locationA > locationB) return order === 'asc' ? 1 : -1;

            const nameA = `${a.firstname} ${a.middlename} ${a.lastname}`.toLowerCase();
            const nameB = `${b.firstname} ${b.middlename} ${b.lastname}`.toLowerCase();

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            return 0;
        });
        setPatients(sortedPatients);
    };

    const filterPatients = (searchTerm) => {
        const filteredPatients = originalPatients.filter(patient => {
            const fullName = `${patient.firstname || ''} ${patient.middlename || ''} ${patient.lastname || ''}`.toLowerCase();

            const matchesFacility = !filters.facilityName ||
                patient.hospital_id === parseInt(filters.facilityName);
            const matchesProvider = !filters.assignedProvider ||
                patient.owning_provider_name?.toLowerCase().includes(filters.assignedProvider.toLowerCase());
            const matchesStatus = !filters.status ||
                patient.status?.toLowerCase() === filters.status.toLowerCase();
            const matchesSearch = !searchTerm ||
                fullName.includes(searchTerm.toLowerCase());

            return matchesFacility && matchesProvider && matchesStatus && matchesSearch;
        });

        setPatients(filteredPatients);
    };

    //create a function to handle the search input
    const handleSearch = (searchTerm) => {
        setSearchQuery(searchTerm);
        filterPatients(searchTerm);
    };

    const AddPatient = () => {
        navigate('/patient', { state: { patient: null, mode: 'add' } });
    }

    const handleDeleteAllPatients = async () => {
        const confirmed = window.confirm("Are you sure you want to delete all patient data? This action cannot be undone.");

        if (confirmed) {
            try {
                const response = await deleteAllPatients();
                const data = await response.json();

                if (response.ok) {
                    setPatients([]);
                    setOriginalPatients([]);
                } else {
                    const errorMessage = data.error || "Failed to delete patient data";
                    alert(errorMessage);
                }
            } catch (error) {
                console.error("Error deleting patients:", error);
                alert("An error occurred while deleting patient data");
            }
        }
    };

    const handleFilterChange = (field, value) => {
        const newFilters = { ...filters, [field]: value };
        setFilters(newFilters);
        localStorage.setItem('patientTableFilters', JSON.stringify(newFilters));

        const filteredResults = originalPatients.filter(patient => {
            const matchesFacility = !newFilters.facilityName ||
                patient.hospital_id === parseInt(newFilters.facilityName);
            const matchesProvider = !newFilters.assignedProvider ||
                patient.owning_provider_name?.toLowerCase().includes(newFilters.assignedProvider.toLowerCase());
            const matchesStatus = !newFilters.status ||
                patient.status?.toLowerCase() === newFilters.status.toLowerCase();
            const matchesSearch = !searchQuery ||
                `${patient.firstname} ${patient.middlename} ${patient.lastname}`.toLowerCase().includes(searchQuery.toLowerCase());

            return matchesFacility && matchesProvider && matchesStatus && matchesSearch;
        });

        setPatients(filteredResults);
    };

    const handleClearFilters = () => {
        const defaultFilters = {
            facilityName: '',
            assignedProvider: '',
            status: ''
        };
        setFilters(defaultFilters);
        setPatients(originalPatients);
        localStorage.setItem('patientTableFilters', JSON.stringify(defaultFilters));
    };

    const handleEditModeToggle = () => {
        const newEditMode = !isEditMode;
        setIsEditMode(newEditMode);

        if (!newEditMode) {
            getPatients().then((p) => {
                setOriginalPatients(p);
                const filteredResults = p.filter(patient => {
                    const matchesFacility = !filters.facilityName ||
                        patient.hospital_id === parseInt(filters.facilityName);
                    const matchesProvider = !filters.assignedProvider ||
                        patient.owning_provider_name?.toLowerCase().includes(filters.assignedProvider.toLowerCase());
                    const matchesStatus = !filters.status ||
                        patient.status?.toLowerCase() === filters.status.toLowerCase();
                    const matchesSearch = !searchQuery ||
                        `${patient.firstname} ${patient.middlename} ${patient.lastname}`.toLowerCase().includes(searchQuery.toLowerCase());

                    return matchesFacility && matchesProvider && matchesStatus && matchesSearch;
                });
                setPatients(filteredResults);
            });
        }
    };


    return (
        <div style={{ height: '100vh', color: 'black', padding: 0, margin: '0.5rem' }} className="UtilitiesContainer">
            <DashboardStats />
            <div className="UtilitiesMainContainer whiteContainerWide">
                <div className="marginContainer">
                    <div className={[styles.topContainer1].join(' ')}>
                        <div className={styles.toggleContainer}>
                            <span>Edit Mode</span>
                            <div className="toggle" onClick={handleEditModeToggle}>
                                <div className={`toggle-slider ${isEditMode ? 'active' : ''}`}></div>
                            </div>
                        </div>
                    </div>
                    <div className={[styles.topContainer, styles.HideMobile].join(' ')}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={styles.m5}>
                            <h3>All patients</h3>
                        </div>
                        <div onClick={handleDeleteAllPatients} className={[styles.button, styles.DeletePatientsBtn].join(' ')} style={{ backgroundColor: '#dc3545', marginLeft: '10px' }}>
                            Delete Patient List
                        </div>
                        <div className={[styles.inputField, styles.m5].join(' ')}>
                            <SearchIcon className={styles.searchIcon} />
                            <input className={styles.input} type="text" value={searchQuery} placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
                        </div>
                        <div onClick={() => AddPatient()} className={[styles.button, styles.AddPatientsBtn].join(' ')}>
                            <PlusIcon className={styles.OptionButtonsIcon} />
                            Add Patients
                        </div>
                    </div>

                    <div className={[styles.topContainer, styles.HideOnDesktop].join(' ')}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={styles.m5}>
                            <h3>All patients</h3>
                        </div>
                        <div onClick={() => AddPatient()} className={[styles.button, styles.AddPatientsBtn].join(' ')}>
                            <PlusIcon className={styles.OptionButtonsIcon} />
                            Add Patients
                        </div>
                        <div style={{ width: '77%', marginTop: '1rem' }} className={[styles.inputField, styles.m5].join(' ')}>
                            <SearchIcon className={styles.searchIcon} />
                            <input className={styles.input} type="text" placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
                        </div>
                    </div>
                    <div className={styles.filterBar}>
                        <FilterIcon className={styles.filterIcon} />
                        <div className={styles.filterField}>
                            <select
                                value={filters.facilityName}
                                onChange={(e) => handleFilterChange('facilityName', e.target.value)}
                                className={styles.filterSelect}
                            >
                                <option value="">All Facilities</option>
                                {hospitals.map(hospital => (
                                    <option key={hospital.id} value={hospital.id}>
                                        {hospital.hospital} ({hospital.abbreviation})
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.filterField}>
                            <select
                                value={filters.assignedProvider}
                                onChange={(e) => handleFilterChange('assignedProvider', e.target.value)}
                                className={styles.filterSelect}
                            >
                                <option value="">All Providers</option>
                                {[...new Set(originalPatients.map(p => p.owning_provider_name))]
                                    .filter(Boolean)
                                    .map(provider => (
                                        <option key={provider} value={provider}>{provider}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className={styles.filterField}>
                            <select
                                value={filters.status}
                                onChange={(e) => handleFilterChange('status', e.target.value)}
                                className={styles.filterSelect}
                            >
                                <option value="">All Status</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                        </div>
                        <button
                            onClick={handleClearFilters}
                            className={styles.clearFiltersBtn}
                        >
                            Clear Filters
                        </button>
                    </div>

                    <div className={styles.tableContainer}>
                        <div className={styles.flexTable}>
                            <div id="tableHeader" className={styles.flexRow}>
                                <div className={styles.flexCell} style={{ flex: 0.1, marginRight: '4px' }}></div>
                                <div className={styles.flexCell} style={{ flex: 0.5 }} onClick={() => handleSort('lastname')}>
                                    Name
                                    {sortOrder.column === 'lastname' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.5 }}>DOB</div>
                                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.5 }}>Admit Date</div>
                                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.5 }} onClick={() => handleSort('hospitalfacilityname')}>
                                    Facility Name
                                    {sortOrder.column === 'hospitalfacilityname' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}</div>
                                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.5 }} onClick={() => handleSort('provider')}>
                                    Provider
                                    {sortOrder.column === 'provider' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}</div>
                                <div className={styles.flexCell} style={{ flex: 0.4 }} onClick={() => handleSort('visittype')}>
                                    Visit Type
                                    {sortOrder.column === 'visittype' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}</div>
                                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.4 }}>Diagnoses</div>
                                <div className={styles.flexCell} style={{ flex: 0.3 }} onClick={() => handleSort('status')}>
                                    Status
                                    {sortOrder.column === 'status' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                                <div className={styles.flexCell} style={{ flex: 0.3 }} onClick={() => handleSort('facesheetalias')}>
                                    Facesheet
                                    {sortOrder.column === 'facesheetalias' ? (<span>{sortOrder.order === 'asc' ? ' ▲' : ' ▼'}</span>) : ' ▼'}
                                </div>
                            </div>
                            {patients.length > 0 ? (
                                patients.map((patient, index) => (
                                    <TableRow
                                        key={index}
                                        patient={patient}
                                        isEditMode={isEditMode}
                                        setPatients={setPatients}
                                    />
                                ))
                            ) : (
                                null
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-6"></div>
        </div>
    );
};

export default PatientList;
