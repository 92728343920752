import { getTokenFromLocalStorage } from '../../helpers';
import { toast } from 'react-toastify';

const fetchShortCuts = async (AllShortCuts, setRenderExpansionData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/text-expander`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + getTokenFromLocalStorage()
      },
    });
    if (response.ok) {
      const data = await response.json();
      AllShortCuts.current = data;
      setRenderExpansionData(data);
    } else {
      console.error('Error:', response.status);
    }
  } catch (error) {
    console.error('Error:', error);
  }
}

const editExpansion = async (sc, exp, edit = true, setEditingId, AllShortCuts, setRenderExpansionData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getTokenFromLocalStorage()
    },
    body: JSON.stringify({ shortcut: sc, expansion: exp, edit })
  }
  try {
    const response = await fetch(process.env.REACT_APP_API_URL + '/users/text-expander', requestOptions);
    const data = await response.json();
    if (response.ok) {
      toast.success('Edited Successfully!');
      setEditingId('');
      fetchShortCuts(AllShortCuts, setRenderExpansionData);
      console.log(data.message);
    } else {
      toast.error(data.message);
      console.log('error');
    }
  } catch (error) {
    console.log(error.message);
  }
};

const autoResize = (e) => {
  const textarea = e.target;
  const scrollPos = window.pageYOffset || document.documentElement.scrollTop;
  if (textarea.scrollHeight > textarea.clientHeight) {
    textarea.style.height = textarea.scrollHeight + 'px';
    window.scrollTo(0, scrollPos);
  }
}

const searchShortCut = async (text, AllShortCuts) => {
  let textarea = document.getElementById("inputText");
  let cursorPosition = textarea.selectionStart;
  let textBeforeCursor = textarea.value.substring(0, cursorPosition);
  let textAfterCursor = textarea.value.substring(cursorPosition);

  AllShortCuts.current.forEach(sc => {
    if (textBeforeCursor.endsWith(sc.shortcut)) {
      textBeforeCursor = textBeforeCursor.substring(0, textBeforeCursor.length - sc.shortcut.length) + sc.expansion;
      textarea.value = (textBeforeCursor + textAfterCursor).replace(/<br>/g, '\n');
      textarea.selectionStart = textarea.selectionEnd = textBeforeCursor.length;
    }
  })
}

const sortExpansions = (shortcuts, sortOrder, setRenderExpansionData, setSortOrder) => {
  const sortedExpansions = [...shortcuts].sort((a, b) => {
    const comparison = a.shortcut.localeCompare(b.shortcut);
    return sortOrder === 'asc' ? comparison : -comparison;
  });

  setRenderExpansionData(sortedExpansions);
  setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
};

const handleExport = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/export-expansions`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + getTokenFromLocalStorage()
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'text_expansions.json';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      console.error('Error:', response.status);
    }
  } catch (error) {
    console.error('Error:', error);
  }
}

const handleImportClick = (handleFileChange) => {
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = 'application/json';
  fileInput.onchange = handleFileChange;
  fileInput.click();
};

const handleFileChange = (event, importExpansions) => {
  const file = event.target.files[0];
  if (file) {
    readJsonFile(file, importExpansions);
  }
};

const readJsonFile = (file, importExpansions) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const jsonData = JSON.parse(event.target.result);
    importExpansions(jsonData);
  };
  reader.readAsText(file);
};

const importExpansions = async (jsonData, setLoading, AllShortCuts, setRenderExpansionData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getTokenFromLocalStorage()
    },
    body: JSON.stringify(jsonData)
  };

  try {
    setLoading(true);
    const response = await fetch(process.env.REACT_APP_API_URL + '/users/import-expansions', requestOptions);
    const data = await response.json();
    if (response.ok) {
      toast.success(data.message);
      fetchShortCuts(AllShortCuts, setRenderExpansionData);
    } else {
      toast.error(data.message);
      console.error('Error:', data.message);
    }
  } catch (error) {
    console.error(error.message);
  } finally {
    setLoading(false);
  }
};

const deleteExpansion = async (id, AllShortCuts, setRenderExpansionData) => {
  // e.preventDefault();
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/text-expander/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': 'Bearer ' + getTokenFromLocalStorage()
      },
    });
    if (response.ok) {
      const data = await response.json();
      toast.success(data.message);
      fetchShortCuts(AllShortCuts, setRenderExpansionData);
    } else {
      console.error('Error:', response.status);
    }
  } catch (error) {
    console.error('Error:', error);
  }
}

const addExpansion = async (shortcut, expandedText, AllShortCuts, setRenderExpansionData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getTokenFromLocalStorage()
    },
    body: JSON.stringify({ shortcut, expansion: expandedText, edit: false })
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/text-expander`, requestOptions);
    const data = await response.json();

    if (response.ok) {
      toast.success('Added Successfully!');
      fetchShortCuts(AllShortCuts, setRenderExpansionData);
      console.log(data.message);
    } else {
      toast.error(data.message);
      console.log('error');
    }
  } catch (error) {
    console.log(error.message);
  }
};

export {
  fetchShortCuts,
  editExpansion,
  autoResize,
  searchShortCut,
  sortExpansions,
  handleExport,
  handleImportClick,
  handleFileChange,
  importExpansions,
  deleteExpansion,
  addExpansion,
};