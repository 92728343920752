import React from 'react';
import styles from "../../assets/css/VisitCode.module.css";

function VisitCode({ visitCodes, handleChange }) {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.column}>
          <h2 className={styles.title}>Procedure Codes</h2>
          <input type="text" className={styles.searchBar} placeholder="Search" />
          <div className={styles.listContainer}>
            <h3 className={styles.categoryTitle}>Initial Hospital Visit</h3>
            <p>99201 Initial hospital visit, moderate complexity</p>
            <p>99202 Initial hospital visit, moderate complexity</p>
            <p>99203 Initial hospital visit, moderate complexity</p>
            <p>99204 Initial hospital visit, moderate complexity</p>
            <p>99205 Initial hospital visit, moderate complexity</p>
          </div>
        </div>
        
        <div className={styles.column}>
          <h2 className={styles.title}>Selected Codes</h2>
          <div className={styles.selectedContainer}>
            <p>No codes selected.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisitCode;
