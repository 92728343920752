import React, { useState } from 'react';
import styles from '../../assets/css/Patient.module.css'
import Facesheet from './Facesheet';
import Admissions from './Admissions';
import PatientDetails from './PatientDetails';
import PatientChargesHistory from './PatientChargesHistory';
import Notes from './Notes';

const AddSinglePatient = ({ location, subMode, handleChoice }) => {

    const patientData = location.state.patient;
    const mode = location.state.mode;
    const [activeTab, setActiveTab] = useState(mode === 'add' ? 'Facesheet' : 'Details');
    const [autoFillChoice, setAutoFillChoice] = useState(location.state.autoFillChoice ? location.state.autoFillChoice : null);
    const [patient, setPatient] = useState(patientData && Object.keys(patientData).length > 0 ? patientData : null);

    const handleTabChange = (tab) => {
        if (mode !== 'view&edit') {
            return;
        }
        setAutoFillChoice(autoFillChoice);
        setActiveTab(tab);
    }

    const handleFacesheetData = (uploadFacesheetChoice, isFacesheetUploaded, autoFillChoice, patientData) => {
        setActiveTab('Details');
        setAutoFillChoice(autoFillChoice);
        if (mode === 'add') {
            setPatient(patientData);
        }
        if (autoFillChoice === true) {
            if (isFacesheetUploaded) {
                setPatient(patientData);
            }
            else {
                setPatient(patient);
            }
        }
        if (isFacesheetUploaded && autoFillChoice === false) {
            const defaultPatientFields = {
                firstname: null,
                lastname: null,
                middlename: null,
                dateofbirth: '',
                room: null,
                gender: null,
                location: null,
                admitdate: '',
                dischargedate: '',
                id: patientData.id,
                facesheetalias: patientData.facesheetalias
            };

            setPatient(defaultPatientFields);
        }
    }

    return (
        <>
            <div className={styles.tabsContainer}>
                <div onClick={() => handleTabChange('Details')} className={`${styles.tab} ${activeTab === 'Details' ? styles.activeTab : ''}`}>Details</div>
                {mode === 'view&edit' &&
                    <div onClick={() => handleTabChange('Admissions')} className={`${styles.tab} ${activeTab === 'Admissions' ? styles.activeTab : ''}`}>Admissions</div>
                }
                {mode === 'add' &&
                    <div onClick={() => handleTabChange('Facesheet')} className={`${styles.tab} ${activeTab === 'Facesheet' ? styles.activeTab : ''}`}>Facesheet</div>
                }
                {mode === 'view&edit' && <div onClick={() => handleTabChange('Notes')} id={styles.ChargesHistory} className={`${styles.tab} ${activeTab === 'Notes' ? styles.activeTab : ''}`}>Notes</div>}
                {mode === 'view&edit' && <div onClick={() => handleTabChange('ChargesHistory')} id={styles.ChargesHistory} className={`${styles.tab} ${activeTab === 'ChargesHistory' ? styles.activeTab : ''}`}>Charges History</div>}
            </div>

            {activeTab === 'Admissions' && <Admissions subMode={subMode} mode={mode} patient={patient} handleChoice={handleChoice} handleTabChange={handleTabChange} />}
            {activeTab === 'Facesheet' && <Facesheet subMode={subMode} mode={mode} patient={patient} handleFacesheetData={handleFacesheetData} />}
            {activeTab === 'Details' && <PatientDetails subMode={subMode} mode={mode} patient={patient} autoFillChoice={autoFillChoice} />}
            {activeTab === 'Notes' && <Notes subMode={subMode} mode={mode} patient={patient} autoFillChoice={autoFillChoice} />}
            {activeTab === 'ChargesHistory' && <PatientChargesHistory patient={patient} />}
        </>
    );
};

export default AddSinglePatient;