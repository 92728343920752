import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTokenFromLocalStorage, columnWidths } from '../../helpers/index';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/charges-plus.svg'
import styles from '../../assets/css/ConsultsTrackingTable.module.css'
import '../../App.css';
import TableRowForConsultsTracking from './TableRowForConsultsTracking';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';

const ConsultsTrackingTable = () => {
    const navigate = useNavigate();
    const [consults, setConsults] = useState([]);
    const [ConsultStatus, setConsultStatus] = useState('open');
    const [originalConsults, setOriginalConsults] = useState([]);
    const [sortOrder, setSortOrder] = useState(() => {
        const savedSort = localStorage.getItem('consultTableSort');
        return savedSort ? JSON.parse(savedSort) : { column: null, order: null };
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [showColumnSelector, setShowColumnSelector] = useState(false);
    const [visibleColumns, setVisibleColumns] = useState(() => {
        const savedColumns = localStorage.getItem('consultTableColumns');

        return savedColumns ? JSON.parse(savedColumns) : {
            name: true,
            facilityName: true,
            roomNumber: true,
            dob: true,
            dateRequested: true,
            timeRequested: true,
            visitDate: true,
            assignedProvider: true,
            insuranceCarrier: true,
            rehabDiagnosis: true,
            rehabRecs: true,
            notes: true,
            initialConsultComplete: true,
            status: true
        };
    });
    const isMobile = window.innerWidth <= 630;
    const [filters, setFilters] = useState(() => {
        const savedFilters = localStorage.getItem('consultTableFilters');
        return savedFilters ? JSON.parse(savedFilters) : {
            facilityName: '',
            assignedProvider: '',
            status: 'open'
        };
    });
    const [hospitals, setHospitals] = useState([]);

    useEffect(() => {
        const fetchHospitals = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/hospitals`, {
                headers: {
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setHospitals(data);
            }
        };
        fetchHospitals();
    }, []);

    const handleFilterChange = (field, value) => {
        const newFilters = { ...filters, [field]: value };
        setFilters(newFilters);

        const filteredResults = originalConsults.filter(consult => {
            const fullName = `${consult.firstname || ''} ${consult.middlename || ''} ${consult.lastname || ''}`.toLowerCase();

            const matchesFacility = !newFilters.facilityName ||
                consult.hospital_id === parseInt(newFilters.facilityName);
            const matchesProvider = !newFilters.assignedProvider ||
                consult.owning_provider_name?.toLowerCase().includes(newFilters.assignedProvider.toLowerCase());
            const matchesStatus = !newFilters.status ||
                consult.status?.toLowerCase() === newFilters.status.toLowerCase();
            const matchesSearch = !searchQuery ||
                fullName.includes(searchQuery.toLowerCase());

            return matchesFacility && matchesProvider && matchesStatus && matchesSearch;
        });

        setConsults(filteredResults);
    };



    useEffect(() => {
        localStorage.setItem('consultTableColumns', JSON.stringify(visibleColumns));
    }, [visibleColumns]);

    const calculateTableWidth = () => {
        const totalWidth = Object.entries(visibleColumns)
            .reduce((sum, [key, isVisible]) => {
                return sum + (isVisible ? columnWidths[key] : 0);
            }, 50); // 50px for the eye icon column

        return `${totalWidth}px`;
    };
    const ColumnSelector = ({ visibleColumns, setVisibleColumns, onClose }) => {
        const [tempColumns, setTempColumns] = useState(visibleColumns);

        const handleAllToggle = () => {
            const allSelected = Object.values(tempColumns).every(v => v);
            setTempColumns(Object.keys(tempColumns).reduce((acc, key) => ({
                ...acc,
                [key]: !allSelected
            }), {}));
        };

        const handleColumnToggle = (key) => {
            setTempColumns(prev => ({
                ...prev,
                [key]: !prev[key]
            }));
        };

        const handleDone = () => {
            if (Object.values(tempColumns).every(v => !v)) {
                alert('Please select at least one column');
                return;
            }
            setVisibleColumns(tempColumns);
            setShowColumnSelector(false);
        };

        return (
            <div className={[styles.mobileColumnSelector].join(' ')}>
                <div className={styles.selectorHeader}>
                    <h4>Select Visible Columns</h4>
                    <button onClick={handleDone}>Done</button>
                </div>
                <div className={styles.columnOptions}>
                    <div className={styles.columnOption}>
                        <label>
                            <input
                                type="checkbox"
                                checked={Object.values(tempColumns).every(v => v)}
                                onChange={handleAllToggle}
                            />
                            All
                        </label>
                    </div>
                    {Object.entries(tempColumns).map(([key, value]) => (
                        <div key={key} className={styles.columnOption}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={value}
                                    onChange={() => handleColumnToggle(key)}
                                />
                                {key.replace(/([A-Z])/g, ' $1')
                                    .trim()
                                    .toLowerCase()
                                    .split(' ')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ')}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (!isEditMode) {
            fetchConsultsList().then((p) => {
                setOriginalConsults(p);
                const filteredResults = p.filter(consult => {
                    const fullName = `${consult.firstname || ''} ${consult.middlename || ''} ${consult.lastname || ''}`.toLowerCase();

                    const matchesFacility = !filters.facilityName ||
                        consult.hospital_id === parseInt(filters.facilityName);
                    const matchesProvider = !filters.assignedProvider ||
                        consult.owning_provider_name?.toLowerCase().includes(filters.assignedProvider.toLowerCase());
                    const matchesStatus = !filters.status ||
                        consult.status?.toLowerCase() === filters.status.toLowerCase();
                    const matchesSearch = !searchQuery ||
                        fullName.includes(searchQuery.toLowerCase());

                    return matchesFacility && matchesProvider && matchesStatus && matchesSearch;
                });
                setConsults(filteredResults);
            });
        }
    }, [isEditMode]);

    useEffect(() => {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            navigate('/signin');
        }

        fetchConsultsList().then((p) => {
            setOriginalConsults(p);
            const filteredResults = p.filter(consult => {
                const fullName = `${consult.firstname || ''} ${consult.middlename || ''} ${consult.lastname || ''}`.toLowerCase();

                const matchesFacility = !filters.facilityName ||
                    consult.hospital_id === parseInt(filters.facilityName);
                const matchesProvider = !filters.assignedProvider ||
                    consult.owning_provider_name?.toLowerCase().includes(filters.assignedProvider.toLowerCase());
                const matchesStatus = !filters.status ||
                    consult.status?.toLowerCase() === filters.status.toLowerCase();
                const matchesSearch = !searchQuery ||
                    fullName.includes(searchQuery.toLowerCase());

                return matchesFacility && matchesProvider && matchesStatus && matchesSearch;
            });
            setConsults(filteredResults);
        });
    }, [navigate]);

    const fetchConsultsList = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/consults-list`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                }
            });

            if (response.ok) {
                const data = await response.json();
                setOriginalConsults(data);

                // Apply saved filters
                let filteredData = data;
                if (filters.facilityName || filters.assignedProvider || filters.status) {
                    filteredData = data.filter(consult => {
                        const matchesFacility = !filters.facilityName ||
                            consult.hospital_id === parseInt(filters.facilityName);
                        const matchesProvider = !filters.assignedProvider ||
                            consult.owning_provider_name?.toLowerCase().includes(filters.assignedProvider.toLowerCase());
                        const matchesStatus = !filters.status ||
                            consult.status?.toLowerCase() === filters.status.toLowerCase();

                        return matchesFacility && matchesProvider && matchesStatus;
                    });
                }

                // Apply saved sort with specific handling for status column
                if (sortOrder.column && sortOrder.order) {
                    const sortedData = [...filteredData].sort((a, b) => {
                        if (!a[sortOrder.column]) return 1;
                        if (!b[sortOrder.column]) return -1;

                        if (sortOrder.column === 'status') {
                            const statusA = a.status?.toLowerCase() || '';
                            const statusB = b.status?.toLowerCase() || '';
                            return sortOrder.order === 'asc'
                                ? statusA.localeCompare(statusB)
                                : statusB.localeCompare(statusA);
                        }

                        const valueA = a[sortOrder.column].toString().toLowerCase();
                        const valueB = b[sortOrder.column].toString().toLowerCase();

                        return sortOrder.order === 'asc'
                            ? valueA.localeCompare(valueB)
                            : valueB.localeCompare(valueA);
                    });
                    setConsults(sortedData);
                } else {
                    setConsults(filteredData);
                }

                return data;
            }
        } catch (error) {
            console.error('Error fetching consults:', error);
        }
    };

    useEffect(() => {
        localStorage.setItem('consultTableSort', JSON.stringify(sortOrder));
    }, [sortOrder]);

    useEffect(() => {
        localStorage.setItem('consultTableFilters', JSON.stringify(filters));
    }, [filters]);

    const handleSort = (column) => {
        let order = sortOrder.column === column ?
            (sortOrder.order === 'desc' ? 'asc' : 'desc') :
            'desc';

        setSortOrder({ column, order });

        const sortedConsults = [...consults].sort((a, b) => {
            if (!a[column]) return 1;
            if (!b[column]) return -1;

            if (column === 'roomnumber') {
                const parseRoom = (room) => {
                    const parts = room.match(/([A-Za-z]+|\d+|[.-])/g) || [];
                    return parts.map(part =>
                        isNaN(part) ? part : part.padStart(5, '0')
                    ).join('');
                };

                const roomA = parseRoom(a[column] || '');
                const roomB = parseRoom(b[column] || '');

                return order === 'asc' ?
                    roomA.localeCompare(roomB) :
                    roomB.localeCompare(roomA);
            }

            if (['daterequested', 'visitdate'].includes(column)) {
                const dateA = new Date(a[column]);
                const dateB = new Date(b[column]);
                return order === 'asc' ? dateA - dateB : dateB - dateA;
            }

            if (column === 'timerequested') {
                const timeA = a[column].split(':').reduce((acc, time) => (60 * acc) + +time);
                const timeB = b[column].split(':').reduce((acc, time) => (60 * acc) + +time);
                return order === 'asc' ? timeA - timeB : timeB - timeA;
            }

            const valueA = a[column].toString().toLowerCase();
            const valueB = b[column].toString().toLowerCase();

            if (valueA < valueB) return order === 'asc' ? -1 : 1;
            if (valueA > valueB) return order === 'asc' ? 1 : -1;

            const nameA = `${a.firstname} ${a.lastname}`.toLowerCase();
            const nameB = `${b.firstname} ${b.lastname}`.toLowerCase();
            return nameA.localeCompare(nameB);
        });

        setConsults(sortedConsults);
    };

    const filterConsults = (searchTerm) => {
        const filteredConsults = originalConsults.filter(consult => {
            const fullName = `${consult.firstname || ''} ${consult.middlename || ''} ${consult.lastname || ''}`.toLowerCase();

            const matchesFacility = !filters.facilityName ||
                consult.hospital_id === parseInt(filters.facilityName);
            const matchesProvider = !filters.assignedProvider ||
                consult.owning_provider_name?.toLowerCase().includes(filters.assignedProvider.toLowerCase());
            const matchesStatus = !filters.status ||
                consult.status?.toLowerCase() === filters.status.toLowerCase();
            const matchesSearch = fullName.includes(searchTerm.toLowerCase());

            return matchesFacility && matchesProvider && matchesStatus && matchesSearch;
        });

        setConsults(filteredConsults);
    };

    //create a function to handle the search input
    const handleSearch = (searchTerm) => {
        setSearchQuery(searchTerm);
        filterConsults(searchTerm);
    };

    const AddConsult = () => {
        navigate('/consult-details', {
            state: {
                consults: null,
                mode: 'add',
            }
        });
    }

    const handleClearFilters = () => {
        const defaultFilters = {
            facilityName: '',
            assignedProvider: '',
            status: 'open'
        };
        setFilters(defaultFilters);

        const openConsults = originalConsults.filter(consult =>
            consult.status?.toLowerCase() === 'open'
        );
        setConsults(openConsults);

        localStorage.setItem('consultTableFilters', JSON.stringify(defaultFilters));
    };

    return (
        <div style={{ paddingBottom: 0 }} className="UtilitiesContainer">
            <div className='NavigationContainer'>
                <BackIcon onClick={() => { navigate(-1) }} className="back-button" />
            </div>
            <div style={{ overflowY: 'hidden', color: 'black', marginBottom: 0, padding: 0 }} className="UtilitiesMainContainer whiteContainerWide">

                <div className={[styles.topContainer1].join(' ')}>
                    <div className={styles.toggleContainer}>
                        <span>Edit Mode</span>
                        <div className="toggle" onClick={() => setIsEditMode(!isEditMode)}>
                            <div className={`toggle-slider ${isEditMode ? 'active' : ''}`}></div>
                        </div>
                    </div>
                </div>

                {showColumnSelector && (
                    <>
                        <div className={styles.overlay} onClick={() => setShowColumnSelector(false)} />
                        <ColumnSelector
                            visibleColumns={visibleColumns}
                            setVisibleColumns={setVisibleColumns}
                            onClose={() => setShowColumnSelector(false)}
                        />
                    </>
                )}
                <div className={[styles.topContainer, styles.HideMobile].join(' ')}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={styles.m5}>
                        <h3>All Consults</h3>
                    </div>
                    <div className={[styles.inputField, styles.m5].join(' ')}>
                        <SearchIcon className={styles.searchIcon} />
                        <input className={styles.input} type="text" value={searchQuery} placeholder="Name search" onChange={(e) => handleSearch(e.target.value)} />
                    </div>
                    <div onClick={() => AddConsult()} className={[styles.button, styles.AddConsultsBtn].join(' ')}>
                        <PlusIcon className={styles.OptionButtonsIcon} />
                        Add Consult
                    </div>
                    <button
                        className={[styles.columnSelectorBtn].join(' ')}
                        onClick={() => setShowColumnSelector(true)}
                    >
                        Select Columns
                    </button>
                </div>

                <div className={[styles.topContainer, styles.HideOnDesktop].join(' ')}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={styles.m5}>
                        <h3>All Consults</h3>
                    </div>
                    <div onClick={() => AddConsult()} className={[styles.button, styles.AddConsultsBtn].join(' ')}>
                        <PlusIcon className={styles.OptionButtonsIcon} />
                        Add Consult
                    </div>
                    <div style={{ width: '77%', marginTop: '1rem' }} className={[styles.inputField, styles.m5].join(' ')}>
                        <SearchIcon className={styles.searchIcon} />
                        <input className={styles.input} type="text" placeholder="Name search" onChange={(e) => handleSearch(e.target.value)} />
                    </div>
                    <button
                        className={[styles.columnSelectorBtn].join(' ')}
                        onClick={() => setShowColumnSelector(true)}
                    >
                        Select Columns
                    </button>
                </div>
                <div className={styles.filterBar}>
                    <FilterIcon className={styles.filterIcon} />
                    <div className={styles.filterField}>
                        <select
                            value={filters.facilityName}
                            onChange={(e) => handleFilterChange('facilityName', e.target.value)}
                            className={styles.filterSelect}
                        >
                            <option value="">All Facilities</option>
                            {hospitals.map(hospital => (
                                <option key={hospital.id} value={hospital.id}>
                                    {hospital.hospital} ({hospital.abbreviation})
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.filterField}>
                        <select
                            value={filters.assignedProvider}
                            onChange={(e) => handleFilterChange('assignedProvider', e.target.value)}
                            className={styles.filterSelect}
                        >
                            <option value="">All Providers</option>
                            {[...new Set(originalConsults.map(c => c.owning_provider_name))]
                                .filter(Boolean)
                                .map(provider => (
                                    <option key={provider} value={provider}>{provider}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className={styles.filterField}>
                        <select
                            value={filters.status}
                            onChange={(e) => handleFilterChange('status', e.target.value)}
                            className={styles.filterSelect}
                        >
                            <option value="">All Status</option>
                            <option value="open">Open</option>
                            <option value="resolved">Resolved</option>
                        </select>
                    </div>
                    <button
                        onClick={handleClearFilters}
                        className={styles.clearFiltersBtn}
                    >
                        Clear Filters
                    </button>
                </div>

                <div style={{ paddingTop: 0, paddingBottom: '10rem' }} className="marginContainer">
                    <div style={{ paddingBottom: '8rem' }} className={styles.tableContainer}>
                        <div className={styles.flexTable}
                            style={{ '--table-width': calculateTableWidth() }}
                        >
                            <div id="tableHeader" className={styles.flexRow}>
                                <div className={styles.flexCell} style={{ width: columnWidths.checkbox }}></div>

                                {visibleColumns.name && (
                                    <div className={`${styles.flexCell} ${styles.sortIndicator}`} style={{ width: columnWidths.name }} onClick={() => handleSort('lastname')}>
                                        Name{sortOrder.column === 'lastname' ? (<span>{sortOrder.order === 'asc' ? '▲' : '▼'}</span>) : '▼'}
                                    </div>
                                )}

                                {visibleColumns.facilityName && (
                                    <div className={`${styles.flexCell} ${styles.sortIndicator}`} style={{
                                        width: columnWidths.facilityName, whiteSpace: 'normal',
                                        wordBreak: 'break-word'
                                    }} onClick={() => handleSort('hospitalfacilityname')}>
                                        Facility Name{sortOrder.column === 'hospitalfacilityname' ? (<span>{sortOrder.order === 'asc' ? '▲' : '▼'}</span>) : '▼'}
                                    </div>
                                )}

                                {visibleColumns.roomNumber && (
                                    <div className={`${styles.flexCell} ${styles.sortIndicator}`} style={{ width: columnWidths.roomNumber }} onClick={() => handleSort('roomnumber')}>
                                        Room No.{sortOrder.column === 'roomnumber' ? (<span>{sortOrder.order === 'asc' ? '▲' : '▼'}</span>) : '▼'}
                                    </div>
                                )}

                                {visibleColumns.dob && (
                                    <div className={styles.flexCell} style={{ width: columnWidths.dob }}>Date of Birth</div>
                                )}

                                {visibleColumns.dateRequested && (
                                    <div className={`${styles.flexCell} ${styles.sortIndicator}`} style={{ width: columnWidths.dateRequested }} onClick={() => handleSort('daterequested')}>
                                        Date Requested{sortOrder.column === 'daterequested' ? (<span>{sortOrder.order === 'asc' ? '▲' : '▼'}</span>) : '▼'}
                                    </div>
                                )}

                                {visibleColumns.timeRequested && (
                                    <div className={`${styles.flexCell} ${styles.sortIndicator}`} style={{
                                        width: columnWidths.timeRequested, whiteSpace: 'normal',
                                        wordBreak: 'break-word'
                                    }} onClick={() => handleSort('timerequested')}>
                                        Time Requested{sortOrder.column === 'timerequested' ? (<span>{sortOrder.order === 'asc' ? '▲' : '▼'}</span>) : '▼'}
                                    </div>
                                )}

                                {visibleColumns.visitDate && (
                                    <div className={`${styles.flexCell} ${styles.sortIndicator}`} style={{ width: columnWidths.visitDate }} onClick={() => handleSort('visitdate')}>
                                        Visit Date{sortOrder.column === 'visitdate' ? (<span>{sortOrder.order === 'asc' ? '▲' : '▼'}</span>) : '▼'}
                                    </div>
                                )}

                                {visibleColumns.assignedProvider && (
                                    <div className={`${styles.flexCell} ${styles.sortIndicator}`} style={{ width: columnWidths.assignedProvider }} onClick={() => handleSort('assignedprovider')}>
                                        Assigned Provider{sortOrder.column === 'assignedprovider' ? (<span>{sortOrder.order === 'asc' ? '▲' : '▼'}</span>) : '▼'}
                                    </div>
                                )}

                                {visibleColumns.insuranceCarrier && (
                                    <div className={`${styles.flexCell} ${styles.sortIndicator}`} style={{ width: columnWidths.insuranceCarrier }} onClick={() => handleSort('insurancecarrier')}>
                                        Insurance Carrier{sortOrder.column === 'insurancecarrier' ? (<span>{sortOrder.order === 'asc' ? '▲' : '▼'}</span>) : '▼'}
                                    </div>
                                )}

                                {visibleColumns.rehabDiagnosis && (
                                    <div className={`${styles.flexCell} ${styles.sortIndicator}`} style={{ width: columnWidths.rehabDiagnosis }} onClick={() => handleSort('rehabdiagnosis')}>
                                        Rehab Diagnosis{sortOrder.column === 'rehabdiagnosis' ? (<span>{sortOrder.order === 'asc' ? '▲' : '▼'}</span>) : '▼'}
                                    </div>
                                )}

                                {visibleColumns.rehabRecs && (
                                    <div className={`${styles.flexCell} ${styles.sortIndicator}`} style={{
                                        width: columnWidths.rehabRecs, whiteSpace: 'normal',
                                        wordBreak: 'break-word'
                                    }} onClick={() => handleSort('rehabrecs')}>
                                        Rehab Recommendations{sortOrder.column === 'rehabrecs' ? (<span>{sortOrder.order === 'asc' ? '▲' : '▼'}</span>) : '▼'}
                                    </div>
                                )}

                                {visibleColumns.notes && (
                                    <div className={`${styles.flexCell} ${styles.sortIndicator}`} style={{ width: columnWidths.notes }} onClick={() => handleSort('notes')}>
                                        Notes{sortOrder.column === 'notes' ? (<span>{sortOrder.order === 'asc' ? '▲' : '▼'}</span>) : '▼'}
                                    </div>
                                )}

                                {visibleColumns.initialConsultComplete && (
                                    <div className={`${styles.flexCell} ${styles.sortIndicator}`} style={{
                                        width: columnWidths.initialConsultComplete, whiteSpace: 'normal',
                                        wordBreak: 'break-word'
                                    }} onClick={() => handleSort('initialconsultcomplete')}>
                                        Initial Consult Complete{sortOrder.column === 'initialconsultcomplete' ? (<span>{sortOrder.order === 'asc' ? '▲' : '▼'}</span>) : '▼'}
                                    </div>
                                )}

                                {visibleColumns.status && (
                                    <div className={`${styles.flexCell} ${styles.sortIndicator}`} style={{ width: columnWidths.status }} onClick={() => handleSort('status')}>
                                        Status{sortOrder.column === 'status' ? (<span>{sortOrder.order === 'asc' ? '▲' : '▼'}</span>) : '▼'}
                                    </div>
                                )}

                            </div>

                            {consults.length > 0 && consults.map((consults, index) => (
                                <TableRowForConsultsTracking
                                    key={index}
                                    consults={consults}
                                    isEditMode={isEditMode}
                                    setConsults={setConsults}
                                    visibleColumns={visibleColumns}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                {consults.length > 0 && (
                    <div className={styles.totalCounter}>
                        Total Records: {consults.length}
                    </div>
                )}
            </div>
            <div className="pb5"></div>
        </div>
    );
};

export default ConsultsTrackingTable;
